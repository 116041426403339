import noImage from '../../../assets/img/noImage.png';
import {ReactComponent as Notification} from "../../../assets/svg/notification.svg";
import {ReactComponent as Chat} from "../../../assets/svg/chat.svg";
import logoMh from '../../../assets/img/logo-branco-mh@2x.png'
import logoGrupo from '../../../assets/img/Grupo-88643-branco2x.png'
import {Link, useLocation} from "react-router-dom";
import menus from "../../../router/menuClient";
import React, {useState} from "react";
import Modal from "../../../components/modal/Modal";
import useModal from "../../../hooks/useModal";
import InputImage from "../../inputs/InputImage";
import Input from "../../inputs/Input";
import Storage from "../../../helpers/Storage";
import userApi from "../../../services/userApi";
import Alert from "../../../helpers/Alert";
import {as} from "@fullcalendar/core/internal-common";
import authApi from "../../../services/authApi";

export default function NavbarClient() {
    const userInitialStater = {
        name: '',
        image: '',
        cpf: '',
        date_birth: '',
        email: '',
        phone: '',
    }
    const { pathname: currentPath } = useLocation();

    const validatePage = currentPath.split('/');
    const [openMenu, setOpenMenu] = useState(false);
    const { modalProps, open, close } = useModal();
    const [user, setUser]: any = useState(userInitialStater);
    const [loading, setLoading] = useState(false);
    const userData = Storage.getUserData();
    const [photoPath, setPhotoPath] = useState(userData?.usuario.photo_path);
    const openModalUser = async () => {
        setLoading(true);

        // @ts-ignore
        const {data, isError} = await userApi.one(userData.usuario.id);

        if (!isError){
            data.image = data.photo_path
            setPhotoPath(data.photo_path)
            setUser(data)
        }

        setLoading(false);
        open()
    }
    const handleChange = (value: any, input: string) => {
        setUser({...user, [input]: value});
    };

    const onRemoveImageUser = () => {
        const newState = {...user};
        newState.image = '';
        setUser(newState);
    }

    const onSaveUser = async () =>{
        const {data, isError} = await userApi.update(user.id, user);
        if (isError){
            if (typeof data === 'object') {
                Object.keys(data).forEach(function(key, index) {
                    Alert.error(data[key][0]);
                });
                return;
            }
            return Alert.error(data.message? data.message : data);
        }
        Alert.success('Editado com sucesso');
        setPhotoPath(data.photo_path)
        Storage.setUserData({ usuario: data });
    }

    async function logout(){
        const {data, isError} = await authApi.logout();
        Storage.removeApiToken();
        Storage.removeUserData();
        Storage.removeUserMenuData();
        window.location.href = '/';
        console.log('aki')
    }

    return(
        <>
            <nav className="bg-system-blue-medium">
                <div className="mx-auto  px-2 sm:px-6 lg:px-8">
                    <div className="relative flex h-[80px] items-center justify-between">

                        <div className="flex flex-1 items-stretch justify-start">
                            <div className="flex  flex-shrink-0  gap-3">
                                <img className="w-auto 2xl:h-9 xl:h-9 lg:h-9 md:h-9 sm:h-9 max-[640px]:h-[20px] max-[640px]:w-[61px]"
                                     src={logoMh}
                                     alt="Your Company"/>
                                <div className={'border-r-2 border-system-gray h-full'}></div>
                                <img className="w-auto 2xl:h-11 xl:h-11 lg:h-11 md:h-11 sm:h-9 max-[640px]:h-[24px] max-[640px]:w-[35px]"
                                     src={logoGrupo}
                                     alt="Your Company"/>
                            </div>
                            <div className="flex items-center gap-4  xs:hidden md:inline-flex">
                                <ul className="flex space-x-4 font-medium ">
                                    {
                                        menus.map((menu, index) => {
                                            return(
                                                <li key={index}>
                                                    <Link to={menu.path} className={`flex items-center p-3 ${currentPath === menu.path ? 'text-system-primary' : 'text-[#B5B5BE]'}   group hover:text-system-primary`}>
                                                        <span className="ms-3">{menu.title}</span>
                                                    </Link>
                                                </li>
                                            )
                                        })
                                    }
                                    <li >
                                        <button onClick={() => logout()} className={`flex items-center p-3 group hover:text-system-primary text-[#B5B5BE]`}>
                                            <span className="ms-3">Sair</span>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>                        
                        <div className="absolute gap-3 inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                            <Link to={'/cliente/mensagens'} className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white">
                                <Chat/>
                            </Link>
                            <Link to={'/cliente/notificacao'} className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white">
                                <Notification/>
                            </Link>

                            <div className="relative ml-3">
                                <div>
                                    <button
                                        onClick={() => openModalUser()}
                                        type="button"
                                            className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none border-border border-2 "
                                            id="user-menu-button" aria-expanded="false" aria-haspopup="true">

                                        <img
                                            className="h-8 w-8 rounded-full"
                                            src={photoPath ? photoPath : noImage}
                                            alt=""
                                        />
                                    </button>
                                </div>
                            </div>

                            {/*Mobile menu button*/}
                            <div className="flex items-center sm:hidden">
                                <button type="button" onClick={() => setOpenMenu(!openMenu)} className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
                                    <span className="absolute -inset-0.5"></span>
                                    <span className="sr-only">Open main menu</span>
                                    <svg className="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"/>
                                    </svg>
                                    <svg className="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${openMenu ? '' : 'sm:hidden max-[640px]:hidden'}`} id="mobile-menu">
                    <div className="space-y-1 px-2 pb-3 pt-2">
                        {
                            menus.map((menu) => {
                                return(
                                    <Link to={menu.path} className={`flex items-center p-3 ${'/'+validatePage === menu.path ? 'text-system-primary border-l-4 border-system-primary' : 'text-[#B5B5BE]'}   group hover:text-system-primary`} aria-current="page">
                                        {/*<menu.icon width={20} height={20} />*/}
                                        <span className="ms-3">{menu.title}</span>
                                    </Link>
                                )
                            })
                        }
                    </div>
                </div>
            </nav>
            <Modal
                size={'mdm'}
                title={""}
                footer={true}
                btnOnclickName={'Salvar'}
                onClick={() => onSaveUser()}
                btnRowReverse={true}
                {...modalProps}
            >
                {
                    loading ?
                        <span className="flex items-center justify-center h-7">
                            <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </span>
                        :
                        <>
                            <div className={'flex w-full justify-center'}>
                                <div className={'col-span-4 flex items-center md:p-3 md:ml-3 md:gap-2'}>
                                    <InputImage
                                        id={'userImage'}
                                        width={'w-[142.75px]'}
                                        height={'h-[142.75px]'}
                                        /*onAddImage={value => setImage(value)}*/
                                        urlPrevew={user.image}
                                        onAddImage={value => handleChange(value, 'image')}
                                        onRemoveImage={() => onRemoveImageUser()}
                                    />
                                </div>
                                {/*<div className={'flex flex-col gap-2'}>
                                    <Input
                                        label={'Usuário'}
                                        disabled
                                    />
                                    <Input
                                        label={'Permissão'}
                                        disabled
                                    />
                                </div>*/}

                            </div>
                            <div className={'sm:w-full grid p-3'}>
                                <div className="grid grid-cols-12 gap-3">
                                    <Input
                                        label={'Nome'}
                                        value={user.name}
                                        className={'md:col-span-5 xs:col-span-12'}
                                        setValue={(v) => handleChange(v, 'name')}
                                    />
                                    <Input
                                        label={'CPF'}
                                        className={'md:col-span-3 xs:col-span-12'}
                                        mask={'CPF'}
                                        value={user.cpf}
                                        setValue={(v) => handleChange(v, 'cpf')}
                                    />
                                    <Input
                                        label={'Data de nascimento'}
                                        value={user.date_birth}
                                        type={'date'}
                                        className={'md:col-span-3 xs:col-span-12'}
                                        setValue={(v) => handleChange(v, 'date_birth')}
                                    />
                                </div>
                                <div className="mt-3 grid grid-cols-12 gap-3">
                                    <Input
                                        label={'E-mail'}
                                        value={user.email}
                                        className={'md:col-span-5 xs:col-span-12'}
                                        inputType={'email'}
                                        setValue={(v) => handleChange(v, 'email')}
                                    />
                                    <Input
                                        label={'Telefone'}
                                        value={user.phone}
                                        className={'md:col-span-3 xs:col-span-12'}
                                        mask={'cellphone'}
                                        setValue={(v) => handleChange(v, 'phone')}
                                    />
                                    {/*<Input
                                        label={'Permissão'}
                                        value={user?.roles ? user?.roles[0].name : ''}
                                        disabled
                                        className={'md:col-span-3 xs:col-span-12'}
                                        setValue={(v) => handleChange(v, 'name')}
                                    />*/}
                                </div>
                            </div>
                        </>
                }

            </Modal>
        </>


    )
}