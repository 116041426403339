/* eslint-disable @typescript-eslint/no-explicit-any */
import {ForgotPassData, LoginData, User} from '../types';
import api, { RequestError, RequestResponse } from './api';

export default  {
  async login(loginData: Partial<LoginData>): Promise<RequestError | RequestResponse<{
    status: true;
    message: string;
    data: any;
    token: string;
    usuario: User;
    menu: any
    permissoes: any
  }>> {
    try {
      const { data } = await api.post('auth', loginData);
      return { data, isError: false };
    } catch (err: any) {
      console.log(err);
      return { 
        data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
        isError: true
      };
    }
  },

  async forgotPass(forgotPassData: ForgotPassData): Promise<RequestError | RequestResponse<{
    status: true;
    message: string;
  }>> {
    try {
      const { data } = await api.post('usuarios/resetar-senha', forgotPassData);
      return { data, isError: false };
    } catch (err: any) {
      console.log(err);
      return {
        data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
        isError: true
      };
    }
  },

  async logout(): Promise<RequestError | RequestResponse<{
    status: true;
    message: string;
  }>> {
    try {
      const { data } = await api.delete('/logout');
      return { data, isError: false };
    } catch (err: any) {
      console.log(err);
      return {
        data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
        isError: true
      };
    }
  },

  async changePassword(id: number | undefined, dataForm: any): Promise<RequestError | RequestResponse<{ status: true; message: string }>> {
    try {
      const { data } = await api.put(`/user/${id}/password`, dataForm);
      return { data, isError: false };
    } catch (err: any) {
      console.log(err);
      return {
        data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
        isError: true
      };
    }
  },

};
