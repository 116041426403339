import cNames from 'classnames';
import { twMerge } from 'tailwind-merge';

const Str = {
  firstToUpperCase(str?: string) {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  },

  addCpfCnpjMask(str?: string) {
    if (!str) return '';
    str = Str.removeNonNumbers(str);
    if (str.length === 11) {// CPF
      return str.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (str.length === 14) {// CNPJ
      return str.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }
    return '';
  },

  addPhoneMask(str?: string, ddd?: string) {
    if (!str) return;
    str = Str.removeNonNumbers(str);
    ddd = ddd || '62';
    ddd = Str.removeNonNumbers(ddd);

    if (str.length === 8) {
      return str.replace(/(\d{4})(\d{4})/, `(${ddd}) 9 $1-$2`);
    }
    
    if (str.length === 9) {
      return str.replace(/(\d{1})(\d{4})(\d{4})/, `(${ddd}) $1 $2-$3`);
    }

    if (str.length === 10) {
      return str.replace(/(\d{2})(\d{4})(\d{4})/, '($1) 9 $2-$3');
    }

    if (str.length === 11) {
      return str.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $3-$4');
    }

    return '';
  }, 

  removeNonNumbers(str?: string) {
    if (!str) return '';
    return str.replace(/\D/g,'');
  },

  convertDate(date: any) {
    if (date) {
      return new Date(date.replace(/-/g, '/')).toLocaleDateString('pt-br');
    }
  },

  convertDateCreatedAt(date: any) {
    if (date) {
      date = date.split("T")[0]

      return new Date(date.replace(/-/g, '/')).toLocaleDateString('pt-br');
    }
  },
  removeTDateCreatedAt(date: any) {
    if (date) {
      date = date.split("T")[0]
      return date;
    }
  },

  convertDateStringComplet(date: any){
    if (date) {
      date = date.split("T")
      const newHours = date[1].substring(0, 5)
      const newDate = date[0]+' '+newHours
      return new Date(newDate).toLocaleString(undefined, {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
        hour: '2-digit',
        hour12: false,
        minute:'2-digit'}) // 'Wednesday, 14/06/2023, 13:43:57'

    }
  },


  countDate(date1: any, date2: any){
    // @ts-ignore
    const diffInMs   = new Date(date2) - new Date(date1)
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
    return(diffInDays);
  },

  getDatesBetween(startDate: any, endDate: any){
    const currentDate = new Date(startDate.getTime());
    const dates = [];
    while (currentDate <= endDate) {
      // @ts-ignore
      dates.push(new Date(currentDate).toLocaleDateString('pt-br'));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  },
  arrayGroupBy (array: any, key: any) {
    return array.reduce((acc: any, item: any) => ({
        ...acc,
        [item[key]]: [...(acc[item[key]] ?? []), item],
      }),
      {})
  },

  currencyMask(value?: number | string) {
    if (!value) return '';
    return Number(value).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
  },

  /** 
   * Concatena classes para o Tailwind
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tw: (className?: string, ...args: any) => twMerge(className, cNames(args)),

  uniqId(){
    var n = Math.floor(Math.random() * 11);
    var k = Math.floor(Math.random() * 1000000);
    return String.fromCharCode(n) + k;
  },


  convertFileToBase64(file: any, callback: any, originalName?: boolean) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    if (originalName){
      reader.onloadend = () => callback({
        file: reader.result,
        original_name: file.name
      });
    }else{
      reader.onloadend = () => callback(reader.result);
    }
  }
};

export default Str;
